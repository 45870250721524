import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Archiv from '@/views/Archiv.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/archiv/:name',
    name: 'Archiv',
    component: Archiv
  },
  // {
  //   path: '*',
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import { useStore } from '@/store'

router.beforeEach( (to, from, next) => {
  const publicPages = ['/login', '/logout'];
  const authRequired = !publicPages.includes(to.path);
  // const loggedIn = localStorage.getItem('authUser');
  const store = useStore();
  // const archivLoggedIn = store.getters.loggedIn
  const archivLoggedIn = store.getters.authUser != null ? true : false


  let loggedIn = false;

  if (localStorage.getItem('authUser') && archivLoggedIn) {
    loggedIn = true
  }

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});

export default router