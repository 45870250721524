import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './ArchivApiInterceptors';
import { LoginRequest, LogoutRequest, RefreshLoginRequest } from '@/models/AuthModels';
import { GetOrdnerRequest, CreateOrdnerRequest, UpdateOrdnerRequest, DeleteOrdnerRequest } from '@/models/OrdnerModels';
import { GetDocsRequest } from "@/models/DocModels";
import { GetCompsRequest, DownloadCompRequest } from "@/models/CompModels";


/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.archivapi.apiUrl;
const API_KEY = config.archivapi.apiKey;


const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));



export default {

  // AUTH
  login(data: LoginRequest): Promise<AxiosResponse> {
    // const test = data.username + ":" + data.userpassword;
    // console.log(test);
    // console.log(window.btoa(test));
    
    // const config = {
    //   headers: {
    //     "Authorization": window.btoa(data.username + ":" + data.userpassword)
    //   }
    // }
    return apiClient.post('/login', data, config);
  },

  loginAzure(idToken: String): Promise<AxiosResponse> {
    const config = {
      headers: {
        "idtoken": idToken.toString()
      }
    }

    return apiClient.get('/login/azure', config);
  },

  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post("/login/refresh", data)
  },

  getAzureLoginConfig(): Promise<AxiosResponse> {
    return apiClient.get('/login/azureconfig')
  },

  logout(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },

  // SESSION
  getSessions(): Promise<AxiosResponse> {
    return apiClient.get('/archiv4session');
  },

  // ARCHIV
  getArchivesBySessionid(): Promise<AxiosResponse> {
    return apiClient.get('/archiv4archive/sessionid');
  },

  // ORDNER
  getOrdner(data: GetOrdnerRequest): Promise<AxiosResponse> {
    let requestUrl = `archiv4ordner/archivid/${data.archivid}`;
    if (data.ownerid != "") {
      requestUrl += `?ownerid=${data.ownerid}`;
    }
    
    return apiClient.get(requestUrl);
  },

  createOrdner(data: CreateOrdnerRequest): Promise<AxiosResponse> {
    return apiClient.post("archiv4ordner", data)
  },
  
  updateOrdner(data: UpdateOrdnerRequest): Promise<AxiosResponse> {
    return apiClient.put("archiv4ordner", data)
  },

  deleteOrdner(data: DeleteOrdnerRequest): Promise<AxiosResponse> {
    return apiClient.delete(`archiv4ordner?archivid=${encodeURIComponent(data.archivid)}&ordid=${encodeURIComponent(data.ordid)}`)
  },

  // DOCS
  getDocs(data: GetDocsRequest): Promise<AxiosResponse> {
    return apiClient.get(`archiv4doc/archivandordid/${data.archivid}/${data.ordid}`);
  },

  // COMPS
  getComps(data: GetCompsRequest): Promise<AxiosResponse> {
    return apiClient.get(`archiv4comp/archivanddocid/${data.archivid}/${data.docid}`);
  },

  getCompFile(data: DownloadCompRequest): Promise<AxiosResponse> {
    return apiClient.get(`/archiv4comp/getfile?archivmandantid=${data.archivmandantid}&archivname=${data.archivname}&docguid=${data.docguid}&compguid=${data.compguid}`);
  }
}