import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-column w-100 main-area" }
const _hoisted_2 = {
  key: 2,
  id: "main-content",
  class: "p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isPublicPage)
      ? (_openBlock(), _createBlock(_component_Sidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isPublicPage)
        ? (_openBlock(), _createBlock(_component_Navbar, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.isPublicPage && (_ctx.authLoading || _ctx.sessionLoading))
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 1,
            text: '...Sie werden abgemeldet...'
          }, null, 8, ["text"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_router_view)
          ]))
    ])
  ], 64))
}