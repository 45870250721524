import { AuthUser } from "@/models/AuthModels";
import { MutationTree } from "vuex";
import { State } from "./state";

import { AzureConfigEinstellung } from "@/models/AuthModels"
import { Configuration } from "@azure/msal-browser"

export enum MutationTypes {
  SetLoading = "SETLOADING",
  LoginSuccess = "LOGINSUCCESS",
  RefreshLoginSuccess = "REFRESHLOGINSUCCESS",
  Logout = "LOGOUT",
  AuthRequestFailure = "AUTHREQUESTFAILURE",
  AuthClearError = "AUTHCLEARERROR",
  SetAzureUsername = "SETAZUREUSERNAME",
  SetIdentityToken = "SETAZUREIDENTITYTOKEN",
  GetAzureLoginConfigSuccess = "GETAZURELOGINCONFIGSUCCESS",
  SetAzureLoginAvailable = "SETAZURELOGINAVAILABLE",
  SetAzureLogin = "SETAZURELOGIN"
}


export type Mutations = {
  [MutationTypes.LoginSuccess](state: State, authUser: AuthUser): void
  [MutationTypes.RefreshLoginSuccess](state: State, authUser: AuthUser): void
  [MutationTypes.Logout](state: State): void
  [MutationTypes.SetAzureUsername](state: State, username: string): void
  [MutationTypes.SetIdentityToken](state: State, token: string): void
  [MutationTypes.GetAzureLoginConfigSuccess](state: State, config: AzureConfigEinstellung): void
  [MutationTypes.SetAzureLoginAvailable](state: State): void
  [MutationTypes.SetAzureLogin](state: State, value: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {


  [MutationTypes.LoginSuccess](state, authUser) {
    state.authUser = authUser;
    state.loggedIn = true;
  },

  [MutationTypes.RefreshLoginSuccess](state, authUser) {
    state.authUser = authUser;
  },

  [MutationTypes.Logout](state) {
    state.loggedIn = false;
    state.authUser = null;
  },

  [MutationTypes.SetAzureUsername](state, username) {
    state.azureUsername = username;
  },

  [MutationTypes.SetIdentityToken](state, token) {
    state.identityToken = token;
  },

  [MutationTypes.SetAzureLoginAvailable](state) {
    state.azureLoginAvailable = true;
  },

  [MutationTypes.SetAzureLogin](state, value) {
    state.azureLogin = value;
  },

  [MutationTypes.GetAzureLoginConfigSuccess](state, config) {
    state.azureLoginAvailable = true
    const msalconfig: Configuration = {
        auth: {
          clientId: config.clientId,
          authority: config.authority,
          redirectUri: config.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      };

    sessionStorage.setItem("msalconfig", JSON.stringify(msalconfig))
    state.msalConfig = msalconfig;
  },
}